._500ccc21 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b389fb86 {
  flex-shrink: 0;
  margin-left: 15px;
  background-color: white;
}

.a3795982 {
  font-weight: 600;
}

._5b35c399 {
  margin-left: 10px;
}
