._717b7e16 {
  font-size: 24px;
  margin-bottom: 0.83em;
}

.bd539083 {
  display: block;
}

.caf04632 {
  display: none;
}

._8c0e92a2 {
  color: var(--color-fg-attention);
  border: none;
}

._8c0e92a2:hover {
  cursor: pointer;
}
