.dfa16c80 {
  font-size: 18px;
}

._26391339 {
}

._26391339 a {
  display: inline-block;
  color: #666;
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 3px;
  margin: 0 3px;
  background-color: white;
  border: 1px solid #cfcfcf;
}

._26391339 a:hover {
  background-color: #f8f8f8;
  -webkit-text-decoration: none;
  text-decoration: none;
}

._31dfe8f0 a {
  font-weight: bold;
  color: black;
}

.a0329937,
._766d2bab {
  -webkit-text-decoration: none;
  text-decoration: none;
}
