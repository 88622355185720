._85a8bd9f {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgb(0, 0, 0, 0.1);
}

._99621a9f {
  box-sizing: border-box;
}

._2618ae29 {
  text-align: center;
}

._0a53cda5 {
  text-align: center;
  color: #00a650;
  display: none;
}

.f8485490 {
  width: 100% !important;
  max-width: 700px !important;
}

.f8485490 iframe {
  width: 100% !important;
  border: 0 !important;
  border-radius: 6px !important;
  display: none;
}
