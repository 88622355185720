:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}

._66e908c6 {
}

.bc1f62ce {
  font-size: 0.9em;
}

._2c87a135 {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

._2c87a135:hover {
  cursor: pointer;
}

._23e0d5ef {
  color: #2f7855;
}

.d58b266b {
  letter-spacing: 0.25px;
}

.ac3cf94e {
  border: none;
  box-shadow: none;
}

._65735826 {
}

.b89afc22 {
  background-color: #fff;
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.09) 0%, rgba(17, 17, 17, 0.04) 100%);
  border: 1px solid rgba(22, 22, 22, 0.2);
  color: #000;
}

.b89afc22:hover {
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.18) 0%, rgba(17, 17, 17, 0.08) 100%);
  border: 1px solid rgba(22, 22, 22, 0.2);
  color: #111;
}

.d0470c7c {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

._32ac0bc3 {
  color: #484f59;
  font-size: 0.9em;
}

._71252860 {
  color: #262626;
  color: var(--color-fg-default);
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 24px;
}

._72cf6082 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.d7eb966d {
  color: #4d4d4d;
  color: var(--color-fg-muted);
  font-size: 14px;
}

.c2b3e5af {
  display: inline;
  padding-left: 4px;
  font-weight: normal;
  font-size: 14px;
}

._56c48b04 {
  display: grid;
  grid-template-columns: 5% 95%;
}

._56c48b04 input {
  margin-top: 0px;
}

._0f187990 {
  color: #4d4d4d;
  color: var(--color-fg-muted);
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

._3a425f83 {
  font-family: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  font-family: var(--code);
  font-size: 13px;
}

.f9d85172 {
}

._565a390e {
  margin-bottom: -25px;
  margin-top: -35px;
}

._305495cf {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
