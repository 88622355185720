:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}

/* Divider */

.c6193a57 {
  width: 100%;
  border-bottom: 1px solid;
  border-color: #e6e6e6;
  border-color: var(--color-border-default);
}

/* Page header */

._4335c0e8 {
  margin-bottom: 2.5rem;
}

._592f3d73 {
}

._099aec25 {
  margin: 0;
}

._68a3e904 {
  color: #4d4d4d !important;
  color: var(--color-fg-muted) !important;
}

._7e8676eb {
  border: 0;
  margin: 0;
  padding: 0;
}

/* Permissions */

.cf2077c3 {
}

._48576b8b {
  width: 200px;
}

._48576b8b > div {
  width: 200px;
}

.f33dca2a {
  padding: 0.5rem !important;
}

.e5228f33 {
  border-bottom: none !important;
  color: #262626 !important;
  color: var(--color-fg-default) !important;
}

@media (hover: hover) {
  .e5228f33:hover,
  .e5228f33:focus {
    color: #ffffff !important;
    color: var(--color-fg-on-emphasis) !important;
    background-color: #666666 !important;
    background-color: var(--color-fg-subtle) !important;
    border-radius: 0.25rem;
    border: solid #666666;
    border: solid var(--color-fg-subtle);
    border-bottom: solid #666666 !important;
    border-bottom: solid var(--color-fg-subtle) !important;
  }
}

.aee5b1e1 {
}

.b22ae0f7:focus {
  outline: 2px solid transparent !important;
}

/* Expiration */

.f216ea4e {
}

._9b048d53 {
  width: 200px;
}

._9b048d53 > div {
  width: 200px;
}

.fdb9eb72 {
  padding: 0.5rem !important;
}

._38567fd1 {
  border-bottom: none !important;
  color: #262626 !important;
  color: var(--color-fg-default) !important;
}

@media (hover: hover) {
  ._38567fd1:hover,
  ._38567fd1:focus {
    color: #ffffff !important;
    color: var(--color-fg-on-emphasis) !important;
    background-color: #666666 !important;
    background-color: var(--color-fg-subtle) !important;
    border-radius: 0.25rem;
  }
}

._2b7279f6 {
}

/* Section */

._92d0cd78 {
  margin-bottom: 2.5rem;
}

.ed3e8d85 {
  color: #262626 !important;
  color: var(--color-fg-default) !important;
}

.a8a801d5 {
}

/* General section */

.a7d66c4e {
}

.b8a95f67 {
}

.e5afac3c {
}

._29d42bfd {
  color: #bb2e3e !important;
  color: var(--color-fg-danger) !important;
}

.b7ecf940 {
  color: #666666 !important;
  color: var(--color-fg-subtle) !important;
}

._16822fa6 {
  color: #4d4d4d;
  color: var(--color-fg-muted);
}

._79720b7e {
}

._317abc52 {
  height: 38px;
  transition:
    border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  resize: vertical;
  background: white;
}

.e2d73ee1 {
  color: #f1b8bc;
}

.f7259b68 {
}

._41582a28 {
  min-height: 5rem;
}

._6a2a1993 {
}

._4db1b69f {
}

/* Allowed IP ranges*/

._145cb6f5 {
  color: #4d4d4d;
  color: var(--color-fg-muted);
}

._7348f302 {
}

.c3d8b373 {
  border-color: #f1b8bc !important;
  border-color: var(--color-border-danger) !important;
}

.c3d8b373:focus-visible {
  outline-color: #f1b8bc;
  outline-color: var(--color-border-danger);
}

.a0717099 {
}

._92a18364 {
  color: #bb2e3e;
  color: var(--color-fg-danger);
}

/* Packages and scopes input */

#_6a943454 {
  letter-spacing: 0.25px;
  background-color: #fff;
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.09) 0%, rgba(17, 17, 17, 0.04) 100%);
  border: 1px solid rgba(22, 22, 22, 0.2);
  color: #000;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

._7cef90ad > *:not(:last-child) {
  margin-bottom: -0.5rem;
}

.eb081c02 {
  display: inline-grid;
}

.eb081c02 > input {
  margin-right: -0.25rem;
}

.c3a7ef58 {
}

.adbdbb36 {
  color: #262626;
  color: var(--color-fg-default);
}

._1a6fc7f2 {
}

._065fcdc9 {
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 25rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 20rem;
}

._7568b93c {
}

._7568b93c > *:not(:last-child) {
  margin-bottom: 1rem;
}

._160d52a2 {
}

.c099dee4 {
  color: #262626;
  color: var(--color-fg-default);
}

._181d540a {
}

._0a2ed87f {
  background: #f7f7f7 !important;
  background: var(--color-bg-subtle) !important;
}

.fdd8b690 {
  color: #262626;
  color: var(--color-fg-default);
}

._2af47568 {
  color: #262626;
  color: var(--color-fg-default);
}

.fbd01b72 {
  padding-top: 8px !important;
  background: none !important;
  color: #4d4d4d !important;
  color: var(--color-fg-muted) !important;
  border: none !important;
}

._57e45de6 {
  border: none !important;
  color: #262626 !important;
  color: var(--color-fg-default) !important;
}

._57e45de6:hover,
._57e45de6:focus {
  outline: 1px solid transparent !important;
}

/* So that the item name can be displayed at the start by hiding the invisible svg icon. */

button._57e45de6 svg {
  display: none !important;
}

/* Organizations input */

._5347a077 {
}

.b19d35ee {
  max-height: 40rem;
  max-width: -moz-max-content;
  max-width: max-content;
}

._88d00a4b {
}

._5c876ef6 {
}

._88d00a4b input[type='checkbox'] {
  margin: 3px 0 3px 3px;
}

/* Summary section */

._04583808 {
  font-weight: 400;
  color: #4d4d4d;
  color: var(--color-fg-muted);
}

._04583808 > ul {
  list-style-type: none;
}

._04583808 > ul > li {
  margin-bottom: 4px;
  position: relative;
}

._04583808 > ul > li::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #4d4d4d;
  border-radius: 50%;
  left: -15px;
  top: 5px;
}

.bd5aa591 {
  font-weight: 600;
}

/* Details page */

._07b9d217 {
}

.a7a877e6 {
  display: inline-block;
}

._37e317b1 {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Fira Mono';
  color: #666666;
  color: var(--color-fg-subtle);
  display: inline-block;
}

.dd59f888 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4d4d4d;
  color: var(--color-fg-muted);
  max-width: 700px;
  margin-top: 1rem;
}

._428fd209 {
  margin-left: -20px;
}

.ffd340bc {
  color: #4d4d4d;
  color: var(--color-fg-muted);
  width: 700px;
}

.a775bc57 {
  margin-bottom: 2.5rem;
}

.b37511f4 {
}

._4a140a68 {
}

._1f995497 {
  color: #4d4d4d;
  color: var(--color-fg-muted);
}

._1e03775a {
}

._96c243f5 {
  vertical-align: top;
}

.caf607b9 {
  border-color: #efd88f !important;
  border-color: var(--color-border-attention) !important;
  color: #886701;
  color: var(--color-fg-attention);
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 18px;
}

._456a664f {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

._112b9e3e {
}

._5ca511ef {
}

.c06d978f {
  border: none;
  padding: 0;
}
