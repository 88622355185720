._2adcc2af {
  flex-grow: 1;
}

._7fb5e603 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

._7cbf6ad7 {
  flex-grow: 1;
  padding: 40px;
}
