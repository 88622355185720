.acb78000 {
  background-color: #f7f7f7;
  min-height: 100vh;
  margin-top: 10px;
}

.acb78000:before {
  content: '';
  background-image: linear-gradient(139deg, #fb8817, #ff4b01, #c12127, #e02aff);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 9999;
}
