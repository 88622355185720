._5a819c56 {
  display: flex;
}

._4dd37cba {
  background-color: #fff;
}

._17d571d5 {
  background-color: #fafafa;
}

.f7e81ac3 {
  background-color: var(--color-bg-attention);
  border-color: var(--color-border-attention);
}

._332b927c {
  height: 1rem;
}

._470255ff {
  line-height: 1.25;
}

._00d28bbf {
  color: var(--color-fg-attention);
}

.e2ebed0d {
  color: var(--color-fg-attention);
}
