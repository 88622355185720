:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}

.e98ba1cc {
}

.e98ba1cc:hover {
  color: #cb3837;
}

._0be7a12f {
  font-family: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  font-family: var(--code);
  letter-spacing: 0px;
  letter-spacing: var(--code-ls);
}

._66c2abad {
  color: #666666;
  color: var(--color-fg-subtle);
}

._464c07bf {
  background-color: #29abe2;
  background-color: var(--wombat-teal);
  border: 1px solid transparent;
}

._625ca439 {
  background-color: #8956ff;
  background-color: var(--wombat-violet);
  border: 1px solid transparent;
}

._9a8a65f4 {
  background-color: #cb3837;
  background-color: var(--wombat-red);
  border: 1px solid transparent;
}

.ef4d7c63 {
}

.b3401251 {
}

._266977fe {
}

.ef4d7c63:hover .cae8e90b {
  opacity: 1;
}

._3422e9b6 {
}

.ef4d7c63 a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.db7ee1ac {
}

.db7ee1ac:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

._8fbbd57d {
  word-break: break-word;
}

._8fbbd57d::first-letter {
  text-transform: capitalize;
}

.f9bd4b83 {
}

.f9bd4b83 img {
  margin-right: 6px;
}

._622b8f03 {
  height: 30px;
  width: 90px;
}

._4ed4187c {
  height: 20%;
}

.d76ccfa5 {
  flex: none;
  width: 75px;
  height: 2px;
  transform-origin: left;
  border-radius: 24px;
}

._89d0b3e5 {
  text-align: right;
  padding-right: 12px;
  line-height: 0.2em;
  flex: 1 1 auto;
  bottom: 0%;
  text-transform: lowercase;
  font-size: 11px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}

._411e5df2 {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  border: 1.5px solid #ccc;
  border-radius: 10px;
  top: 105%;
  left: 50%;
  transform: translate(-50%, 0);
}

._4ed4187c:hover ._411e5df2 {
  display: block;
  /* TODO: these aren't showing up for some reason */
}

._6951a207 {
  height: 17px;
  padding: 0 8px;
}

.c9d94a14 {
}

.cf33f2b9 {
}

._69ac86b8 {
  transition: all 0.15s ease-in;
  margin: 0 7px 7px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  letter-spacing: 0.4px;
}

._39bb688c {
  background-color: #efe7fc;
  transition: all 0.15s ease-in;
  margin-left: 25px;
  letter-spacing: 0.4px;
}

._39bb688c:hover {
  background-color: #e5d7fb;
}

._69ac86b8:first-of-type {
  margin-left: 0;
}

._0d2164ff {
  flex-grow: 1;
}

._5c95be4e {
}

.c62c138e {
}

.bea55649 {
}

._045facde {
}

._50cd544f {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
