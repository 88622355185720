._1d00ea9b {
}

.f8750038 {
}

.f72023df {
}

._81de491d {
  line-height: 20px;
  font-size: 0.8rem;
  overflow: auto;
}

._0288209a {
  color: #2f7855;
}

._030a0000 {
}

._6edd678c {
}

@media only screen and (max-width: 320px) {
  ._030a0000 {
    flex-direction: column;
  }

  ._6edd678c {
  }
}
