.ff0e7e5c {
  flex: 0 0 auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 10px 0;
}

.e4e34577 {
}

._43e27900 {
  flex-grow: 0;
}

._43e27900 svg {
  width: 96px;
}
