._72d58f38 {
}

.ef05047f {
  color: hsl(120, 75%, 25%);
}

._69f3a0ae {
  color: hsl(0, 75%, 35%);
}
