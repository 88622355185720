._748cc31f {
  justify-self: flex-start;
}

._2ffd8dd7 {
  justify-self: flex-end;
}

._66549089 {
  flex: 1;
}

._270f475a {
  align-items: center;
}
