._24d21afa {
}

._16a82db2 {
  top: -25px;
  left: 50%;
}

._7dc916aa {
  left: -50%;
}

.c8578b02 {
  justify-content: space-between;
}

._6d73ca24 {
}

.b911aca9 {
  align-items: center;
  z-index: 1;
}

._612d3ad9 {
  border-color: #999999;
  height: 1px;
  top: 20px;
}
