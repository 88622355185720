._1dd813d2 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 206px;
  text-align: center;
  padding-top: 80px;
}

.dc6cb105 {
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
}

._571e2305 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

._3ef12bb4 {
  margin-top: 20px;
}

.b6ce8ef1 {
  display: flex;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.f7869962 {
  margin-left: 10px;
}

._1184e1d6 {
  color: #262626;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 8px;
}

._0a64b6f2 {
  color: #4d4d4d;
  font-size: 14px;
  line-height: 14px;
}

._0a64b6f2 a {
  color: #4d4d4d;
}

._3f09940d {
  text-decoration-line: underline;
}

._6e91f641 {
  text-decoration-line: underline;
}

._38695c87 {
  margin: 0 4px;
}

._66ff4e18 {
  margin-bottom: 4px;
}

._6da8c80b {
}

.beb36dd7 {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.beb36dd7:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
