._56365793 {
  padding: 1.25rem 0rem;
}

.df71319c {
  color: #4a4a4a;
}

._343a5c94 {
  color: #4c4c4c;
}
