._088eeb66 {
  flex-flow: row wrap;
}

hgroup {
  margin-bottom: 50px;
}

._3b8e8252 {
}

.ada9b748 {
}

._088eeb66 textarea {
  min-height: 8em;
}

._088eeb66 hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

._088eeb66 select {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0 0.75em 0;
  color: rgba(0, 0, 0, 0.7);
}

.a4e87cde {
  margin-top: 0;
  margin-bottom: 2em;
}

.ce29b9d7 {
  display: inline;
  color: #cb3837;
}

.bb65ee2b {
  background-color: #fb3b49;
  box-shadow: 5px 5px 0 rgba(251, 59, 73, 0.2);
  color: white;
  border-radius: 0;
  font-family: 'Poppins';
}

.b0a50a23:after {
  clear: both;
}

._5e9bafb4 {
  display: flex;
  border: solid 1px #888888;
  float: left;
  box-sizing: border-box;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: calc(50% - 0.5em);
  margin-bottom: 1em;
  text-align: center;
  padding: 1em;
  min-height: 4.5em;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7);
}

._5e9bafb4:nth-child(odd) {
  margin-right: 0.5em;
}

._5e9bafb4:nth-child(even) {
  margin-left: 0.5em;
}

._5e9bafb4:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.ce29b9d7:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

.e0f85141 {
}

._550e209d {
}

._9780b234 {
}

._916a6302 {
}

._916a6302 a {
  display: inline-block;
  vertical-align: top;
  line-height: 32px;
}

._51441162 {
  line-height: 1.4;
}

._9716989b {
  margin-top: 1em;
}

._4a9e6735 {
  display: none;
  visibility: hidden;
}

._2b4dcaf5 {
  color: #cb3837;
  margin-top: 0;
}

._947c21af {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

._947c21af:hover {
  cursor: pointer;
}

.e6ae8d49 {
  margin: 3% 0;
}

._6d611356 {
  color: var(--color-fg-muted);
  font-size: 14px;
}

._9fb4573d {
}

._04c1e7ad {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 16px;
  color: #262626;
}

._37aa2fa6 {
}

._8e158e54 {
}

._0ab83364 {
  cursor: pointer;
  color: var(--color-fg-muted);
  font-size: 14px;
  border: none;
  background: none !important;
  padding: 0 !important;
  margin-bottom: 0.5rem;
}

._8e94f7e0 {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
