.fbd04dd1 {
  position: absolute;
  right: 15px;
  left: auto;
  border: none;
  background: none;
  cursor: pointer;
}

.fbd04dd1 svg {
  width: 0.9em;
  height: 0.9em;
  flex: none;
  margin-right: 3px;
  margin-left: 0px;
  margin-top: 3px;
  fill: rgba(0, 0, 0, 0.5);
}

.fbd04dd1:hover svg {
  fill: rgba(0, 0, 0, 0.8);
}
