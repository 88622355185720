:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}

._5c0848d5 {
}

.aea5c05c {
}

.aea5c05c._5c52048f {
  overflow-x: hidden;
}

._67b54957 {
}

._571987eb {
}

.be718c72 {
}

._87ac780f {
}

._74db5315 {
}

._37d2a67c {
  color: #444;
}

._37d2a67c:hover {
  color: #444;
}

._0e186e7f {
  width: 42px;
}

._01afe7c5 {
  color: #158572;
}

.cf2360a0 {
}

.eceda99a {
  background: #1aa974;
}

._168af4fe {
  background: #bb0000;
}

._2125dba0 {
  color: #ffffff;
}

._42241bcb {
}

@keyframes _40b21187 {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 88px;
    opacity: 1;
  }
}

._40b21187 {
  overflow: hidden;
  height: 88px;
  animation-duration: 0.3s;
  animation-name: _40b21187;
  transition: height 0.3s ease-out;
}

.f177e54f {
  color: rgb(255, 0, 0);
  white-space: normal;
}

._411f2f68 {
  color: rgb(0, 0, 238);
}

._004fe154 {
}

._49f0a8b8 {
  border: 1px solid #cccccc;
  height: 2px;
  top: 4px;
}

.ea5f1b0a {
  align-items: center;
  z-index: 1;
}

.b117e7fa {
  width: 8px;
  height: 8px;
  background-color: #cccccc;
  border-radius: 50%;
}

.f8b9e58a {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 2px solid #4fa678;
  border-radius: 50%;
}

._5d49a807 {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  border-radius: 50%;
}

.c00514c7 {
  color: #cb3837;
  color: var(--npmRed);
}

._3c6847f7 {
  background: linear-gradient(180deg, #ffffff 0%, #f5f6f5 100%);
  border: 1px solid #d2d1d1;
  box-sizing: border-box;
  border-radius: 4px;
  width: 140px;
  height: 36px;
  opacity: 0.8;
}

._43b114ae {
  font-weight: normal;
}

.e6a1fcad {
  color: #4d4d4d;
  color: var(--color-fg-muted);
  margin-top: 16px;
}

.fe277b13 {
  padding-left: 8px;
  padding-right: 8px;
  height: 30px;
}

._29fcab9f {
  align-items: center;
}
