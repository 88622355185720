:root {
  /*
    Semantically-named custom properties matching npm Figma Library
    and Primer naming conventions.

    These should be preferred over the below variables.

    Note that each variable has a corresponding class;
    `--color-fg-default` can be applied with `.color-fg-default`, etc.

    https://www.figma.com/file/qewGdfS6NAH5yOR3gKtWK9/?node-id=3%3A41
  */
  --color-fg-default: #262626;
  --color-fg-muted: #4d4d4d;
  --color-fg-subtle: #666666;
  --color-fg-on-emphasis: #ffffff;
  --color-fg-brand: #cb3837;
  --color-fg-accent: #196cb2;
  --color-fg-success: #107010;
  --color-fg-attention: #886701;
  --color-fg-danger: #bb2e3e;

  --color-bg-default: #ffffff;
  --color-bg-subtle: #f7f7f7;
  --color-bg-inset: #f2f2f2;
  --color-bg-emphasis: #262626;
  --color-bg-accent: #f2f9ff;
  --color-bg-success: #dcfdd9;
  --color-bg-attention: #fff5d8;
  --color-bg-danger: #ffdbdb;

  --color-border-default: #e6e6e6;
  --color-border-muted: #f2f2f2;
  --color-border-strong: #8f8f8f;
  --color-border-accent: #afcee9;
  --color-border-accent-emphasis: #0969da;
  --color-border-success: #93d58a;
  --color-border-success-emphasis: #107010;
  --color-border-attention: #efd88f;
  --color-border-danger: #f1b8bc;

  --color-shadow-large: 0 8px 24px rgba(140, 149, 159, 0.2);

  /*
    Legacy color variables — please avoid!
  */
  --wombat-red-hover: #c40b0a;
  --button-green: #00c642;
  --wombat-yellow: #ffcd3a;
  --wombat-violet: #8956ff;
  --wombat-purple: #c836c3;
  --wombat-green: #00c642;
  --wombat-teal: #29abe2;
  --light-blue: #70b8ff;
  --wombat-red: #cb3837;
  --header-color: #000;
  --bg-dark: #cb3837;
  --npmRed: #cb3837;
  --npmLightRed: #fb3e44;
  --blue: #357edd;
  --bg: #fff;
  --background-color: #fafafa;
  --wombat-bg-red: rgba(203, 55, 56, 0.1);

  --code: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  --code-ls: 0px;
  --code-lh: 24px;

  --standardCardTransition: all 0.2s ease;

  --header-letter-spacing: 0.015625em;

  --readme-font-size: 18px;
  --readme-line-height: 1.6;
  --copy-color: rgba(0, 0, 0, 0.85);
  --code-bg: #f7f7f7;
  --code-box-radius: 2px;
}

.f7d42219 {
  color: #107010;
  color: var(--color-fg-success);
  background-color: #dcfdd9;
  background-color: var(--color-bg-success);
  border-color: #93d58a;
  border-color: var(--color-border-success);
}

._04f0e373 {
  margin-top: -2px;
}

._26da243d {
}

._26da243d p,
h4 {
  margin: 0;
}

._26da243d pre {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.5em;
  color: #333;
}

._26da243d code {
  font-size: 14px;
}

._64f1bcfb {
  margin-right: 0.5em;
  color: white;
  background-color: #00c642;
  background-color: var(--button-green);
}

._70c3b276 {
  margin-top: 0;
}

._70c3b276:hover {
  background-color: #cb3837;
  background-color: var(--wombat-red);
}

.b88f0a56 {
  color: #cb3837;
  color: var(--wombat-red);
}

.b88f0a56:focus,
.b88f0a56:hover {
  -webkit-text-decoration: underline;
  text-decoration: underline;
}

._93416561 {
  font-size: 1em;
  margin: 0;
}

._14e2297a {
  display: block;
}

@media (max-width: 320px) {
  ._14e2297a {
    display: flex;
  }

  ._14e2297a input {
    font-size: 0.7rem;
  }
}

._09869079 {
  margin-top: -2px;
}

._09869079:focus {
  outline: revert !important;
}

@media screen and (max-width: 600px) {
  .d4521ddd {
    transform: translateY(-150%);
  }
}

._5534f533 {
  min-width: 200px;
}

._5534f533 svg {
  height: 12px;
  margin-right: 5px;
}

._5534f533.ea275126 a:focus,
._5534f533.ea275126:hover a {
  opacity: 0;
}

.b38b2ffc {
  font-size: 17px;
  font-weight: 500;
}

.b38b2ffc:hover,
.b38b2ffc:focus {
  color: #ffffff !important;
  color: var(--color-fg-on-emphasis) !important;
  background-color: #666666 !important;
  background-color: var(--color-fg-subtle) !important;
  border-radius: 0.25rem;
}

._0179d8d8 {
  position: relative;
}

._634bf952 {
  font-family: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
  width: 26rem;
  border-radius: 0.25rem 0 0 0.25rem;
}

._76399b1c {
  border-radius: 0 0.25rem 0.25rem 0;
  border-left-width: 0px;
}

._96567f36 {
  font-family: 'Fira Mono', 'Andale Mono', 'Consolas', monospace;
}

.c0192518 {
  font-family: 'Source Sans Pro';
  color: #4d4d4d;
  color: var(--color-fg-muted);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

._1e7baf70 {
  font-family: 'Source Sans Pro';
  color: #4d4d4d;
  color: var(--color-fg-muted);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.e0e71cb5 {
  font-family: 'Fira Mono';
  font-size: 12px;
  line-height: 12px;
  color: #666666;
  color: var(--color-fg-subtle);
}

.c0192518:hover {
  cursor: pointer;
}

._850262ff {
  margin-top: -5px;
}

.bb6756a2 {
  border-color: #107010 !important;
  border-color: var(--color-border-success-emphasis) !important;
  color: #107010;
  color: var(--color-fg-success);
}

._75a22062 {
  border-color: #efd88f !important;
  border-color: var(--color-border-attention) !important;
  color: #886701;
  color: var(--color-fg-attention);
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 18px;
}
